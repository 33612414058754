import React from 'react';

import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';

import { Layout } from 'modules/common/components/Layout';
import { ShopPage } from 'modules/common/components/ShopPage';

export const Page404 = () => {
  const { tu } = useTranslation('common');

  return (
    <ShopPage title={tu('not-found', 'titles')}>
      <Layout minHeight centered>
        <SpacedContainer centered>
          <h1 className="font-sans text-6xl font-bold">404</h1>
          <p className="text-gray-500">{tu('404-text')}</p>
        </SpacedContainer>
      </Layout>
    </ShopPage>
  );
};
